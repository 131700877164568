(function () {
  const cookie = document.getElementById('cookie');
  document.addEventListener('DOMContentLoaded', function () {
    const localApprove = localStorage.getItem('cookieApprove');
    if (!JSON.parse(localApprove)) {
      cookie.classList.add('cookieVisible');
    } else {
      cookie.classList.remove('cookieVisible')
    }
  })
  document.getElementById('approvedCookie').addEventListener('click', function () {
    localStorage.setItem('cookieApprove', JSON.stringify(true));
    cookie.classList.remove('cookieVisible');
  })
})()
